.roka-simple-dropdown {
    position: relative;

    .roka-dropdown-button {
        svg {
            position: relative;
            left: -9px;
            top: 2px;
            color: $card-dark-border-color;
            transition: 0.3s ease;
        }

        border: 1px solid $card-dark-border-color;
        transition: 0.3s ease;
        cursor: pointer;

        &:hover,
        &.active {
            border-color: $secondary-ui-color;
            color: $text-color-light-secondary;

            svg {
                color: $secondary-ui-color;
            }
        }

        border-radius: 4px;
        padding: 10px 20px;
        margin-bottom: 15px;
        color: $text-color-light-primary;
    }


    ul.roka-dropdown-options {
        width: 200px;
        position: absolute;
        top: 44px;
        right: 0;
        border-radius: 4px;
        background: $card-dark-background;
        border: 1px solid $card-dark-border-color;
        display: none;
        z-index: 9999;
        overflow: hidden;
        box-shadow: 0 0 7px 7px rgba(0, 0, 0, 0.3);

        &.active {
            display: block;
        }

        >li {
            display: block;
            padding: 12px 26px;
            border-top: 1px solid $card-dark-border-color;
            cursor: pointer;

            &:hover {
                color: $text-color-light-secondary;
                background-color: darken($card-dark-background, 2%);
            }

            &:first-child {
                border: none;
            }
        }
    }
}