@import "./colors.scss";
@import "./FlamingoAttribution.scss";

.main {
  position: relative;
  width: 100%;
  margin-top: 40px;

  .dashboard-select {
    float: right;
    position: relative;
    width: 200px;
    font-size: 14px;
  }

  .flamingo-attribution {
    display: flex;
    margin-top: 40px;
    padding-bottom: 40px;
    height: calc($flamingo-attribution-height + 30px);

    .wrapper {
      margin-left: auto;
      // background: none;
    }
  }
}