@import "./colors.scss";

.protected-layout {
  width: min(90%, 1200px);
  margin: 0 auto;
}

nav {
  margin-top: 20px;
  display: flex;
  align-items: center;

  img {
    width: 48px;
    height: 48px;
  }

  div.location-name {
    margin-left: 2px;
    font-weight: bold;
  }

  div.settings {
    margin-left: auto;
    position: relative;
  }

  div.toggle-settings-button {
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0);
    transition: border-color 0.2s ease;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    &:hover {
      border-color: $card-dark-border-color;
    }

    &.active {
      border-color: $secondary-ui-color;
      &:hover {
        border-color: $secondary-ui-color;
      }
    }
  }

  div.settings-dropdown-items {
    top: 48px;
    right: 0;
    position: absolute;
    border-radius: 4px;
    background: $card-dark-background;
    border: 1px solid $card-dark-border-color;
    display: none;
    z-index: 9999;
    overflow: hidden;
    box-shadow: 0 0 7px 7px rgba(0, 0, 0, 0.3);

    &.active {
      display: block;
    }

    .setting-item {
      display: flex;
      align-items: center;
      padding: 12px 20px;
      font-size: 14px;
      border-top: 1px solid $card-dark-border-color;
      cursor: pointer;

      &:hover {
        color: $text-color-light-secondary;
        background-color: darken($card-dark-background, 2%);
      }

      &:first-child {
        border: none;
      }

      > svg {
        margin-right: 10px;
      }
    }
  }
}
