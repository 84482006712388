@import "./colors.scss";

button.roka-btn {
  /* Reset button styling START */
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  appearance: none;
  -webkit-appearance: none;

  /* Remove excess padding and border in Firefox 4+ */
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  // Common CSS
  width: 100%;
  font-weight: 500;
  padding: 10px 20px;
  border-radius: 4px;
}

button.roka-btn.flat {
  cursor: pointer;
  background-color: $btn-flat-default-bg-color;
  transition: 0.3s ease;

  &:hover &:not(:disabled) {
    background-color: $btn-flat-hover-bg-color;
  }

  &:active &:not(:disabled) {
    background-color: $btn-outline-active-color;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: $btn-flat-disabled-bg-color;
    color: $btn-flat-disabled-text-color;
  }
}

button.roka-btn.outline {
  cursor: pointer;
  color: $btn-outline-default-color;
  border: 1px solid $btn-outline-default-color;
  transition: 0.3s ease;

  &:hover &:not(:disabled) {
    color: $btn-outline-hover-color;
    border: 1px solid $btn-outline-hover-color;
  }

  &:active &:not(:disabled) {
    color: $btn-outline-active-color;
    border: 1px solid $btn-outline-active-color;
  }

  &:disabled {
    color: $btn-outline-disabled-color;
    border: 1px solid $btn-outline-disabled-color;
    cursor: not-allowed;
  }
}
