@use "./mobile.scss" as mobile;
@import "./colors.scss";

.settings-view {
  padding-top: 20px;
  h1 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .setting {
    h4.label {
      font-weight: bold;
      margin-bottom: 16px;
    }

    p.description {
      color: $text-color-light-primary;
      font-size: 14px;
      margin-bottom: 16px;
    }

    .form {
      display: flex;
      align-items: center;

      button.roka-btn {
        font-size: 14px;
        width: 100px;
        margin-left: auto;
        padding: 6px 0;
      }
    }
  }

  .go-back {
    margin-top: 20px;
  }
}
