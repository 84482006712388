@import "./colors.scss";
@import "./FlamingoAttribution.scss";

$flamingo-attribution-height: 60px;

.login-view {
  height: 100%;

  .centered {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .roka-card {
    z-index: 2;
    width: 420px;
    max-width: calc(100% - 40px);
    text-align: center;
  }

  img.roka-logo {
    width: 80px;
  }

  h1 {
    color: $text-color-light-secondary;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 14px;
  }

  p {
    color: $text-color-light-primary;
    font-size: 14px;
    margin-bottom: 20px;
  }

  .flamingo-attribution {
    position: absolute;
    bottom: 30px;
    right: 30px;
  }

  .background {
    position: fixed;
    z-index: 0;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;

    .bg-image {
      width: 100%;
      height: 100%;

      img.roka-login-background {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .bg-image-overlay {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.85);
    }
  }
}
