@use "../../style/mobile.scss" as mobile;
@import "../../style/colors.scss";

.bar-chart-dashboard,
.line-chart-dashboard {
    width: 100%;
    margin-top: 40px;
    clear: both;

    .header {
        @include mobile.respond-to(mobile) {
            display: block;

            input.bp5-input {
                width: 100%;
                margin-bottom: 14px;
            }
        }

        @include mobile.respond-to(desktop) {
            display: flex;

            .right {
                margin-left: auto;
                display: flex;
                align-items: baseline;
            }

            .preset-dropdown {
                margin-right: 10px;
            }
        }

        .preset-dropdown {
            font-size: 13px;
        }
    }

    .roka-metrics-chart {
       height: 55vh;
    }

    @include mobile.respond-to(mobile) {
        .roka-metrics-chart {
            margin-top: 40px;
            position: relative;
            left: -14px;
            width: calc(100% + 24px);
            height: 35vh;
        }
    }
}

.bar-chart-dashboard,
.live-metrics-dashboard {
    .last-updated {
        display: flex;
        align-items: center;

        .indicator {
            font-size: 24px;
            margin-right: 8px;

            &.disconnected {
                color: #E76A6E;
            }
        }

        .time {
            font-size: 12px;
            color: $text-color-light-primary;
            opacity: 0.8;
        }
    }

    .metrics {
        width: 100%;

        >div {
            padding: 20px 24px;

            span.metric-header {
                font-size: 12px;
                font-weight: bold;
                display: flex;
                align-items: center;
                text-transform: uppercase;
                color: $text-color-light-secondary;

                .metric-icon {
                    position: relative;
                    font-size: 16px;
                    top: -1px;
                    width: 24px;
                    height: 24px;
                    background-color: $primary-ui-color;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 10px;
                }
            }

            span.value {
                margin-top: 20px;
                display: block;
                font-weight: lighter;
                font-size: 70px;
            }
        }

        >div.metric-1 {
            border: none;
            border-radius: 4px;
            background-color: map-get($zinc, 200);

            span.metric-header {
                color: $text-color-dark-secondary;

                .metric-icon {
                    background-color: $secondary-ui-color;
                }
            }

            span.value {
                color: $text-color-dark-primary;
            }
        }

        @include mobile.respond-to(mobile) {
            display: block;
            margin-top: 30px;

            >div {
                margin: 20px auto;
            }
        }

        @include mobile.respond-to(desktop) {
            display: flex;

            >div {
                flex-grow: 1;
                margin-right: 20px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}

.chart {
    margin-top: 20px;

    h1 {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: bold;
        margin-bottom: 6px;
        color: $text-color-light-secondary;
    }

    p {
        font-size: 14px;
        font-weight: lighter;
        margin-bottom: 20px;
    }
}

.bp5-input-group {
    input.bp5-input {
        height: auto;
        width: auto;
        padding: 3px 15px;
        font-size: 13px;
        border-radius: 4px;
        border: 1px solid $card-dark-border-color;
        box-shadow: none;
        margin-right: 10px;
    }
}