// From https://uicolors.app/browse/tailwind-colors
$emerald: (
    50: #ecfdf5,
    100: #d1fae5,
    200: #a7f3d0,
    300: #6ee7b7,
    400: #34d399,
    500: #10b981,
    600: #059669,
    700: #047857,
    800: #065f46,
    900: #064e3b,
    950: #022c22,
);

$zinc: (
    50: #fafafa,
    100: #f4f4f5,
    200: #e4e4e7,
    300: #d4d4d8,
    400: #a1a1aa,
    500: #71717a,
    600: #52525b,
    700: #3f3f46,
    800: #27272a,
    900: #18181b,
    950: #09090b,
);

// Background
$background-color: #0E0E12;

// UI Colors
$primary-ui-color: map-get($zinc, 500);
$secondary-ui-color: map-get($zinc, 300);

// Banners
$banner-dark-background: map-get($zinc, 950);
$banner-dark-icon-primary: map-get($zinc, 400);
$banner-dark-icon-secondary: map-get($zinc, 900);

$banner-light-background: map-get($zinc, 950);
$banner-light-icon-primary: map-get($zinc, 300);
$banner-light-icon-secondar: map-get($zinc, 900);

// Text
$text-color-light-primary: map-get($zinc, 50);
$text-color-light-secondary: map-get($zinc, 100);

$text-color-dark-primary: map-get($zinc, 950);
$text-color-dark-secondary: map-get($zinc, 600);

$text-color-gray-on-dark: #777;

// Buttons
$btn-flat-default-bg-color: map-get($zinc, 500);
$btn-flat-hover-bg-color: map-get($zinc, 600);
$btn-flat-active-bg-color: map-get($zinc, 700);
$btn-flat-disabled-bg-color: map-get($zinc, 950);
$btn-flat-disabled-text-color: map-get($zinc, 700);

$btn-outline-default-color: map-get($zinc, 600);
$btn-outline-hover-color: map-get($zinc, 700);
$btn-outline-active-color: map-get($zinc, 800);
$btn-outline-disabled-color: map-get($zinc, 900);

// Cards
$card-dark-background: #18181B;
$card-dark-border-color: #27272a;

// Input fields?