$breakpoint-mobile: 1200px;

@mixin respond-to($breakpoint) {
    @if $breakpoint ==mobile {
        @media (max-width: $breakpoint-mobile) {
            @content;
        }
    }

    @else if $breakpoint ==desktop {
        @media (min-width: $breakpoint-mobile) {
            @content;
        }
    }
}