// Reset css
@import "./reset.scss";

// Blueprint stuff
@import "@blueprintjs/core/lib/css/blueprint.css";
@import "@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
@import "@blueprintjs/select/lib/css/blueprint-select.css";
@import "@blueprintjs/datetime2/lib/css/blueprint-datetime2.css";

@import "./colors.scss";
@import "./mobile.scss";

// Global
html,
body,
#root {
  width: 100%;
  height: 100%;
  background-color: $background-color;

  color: $text-color-light-primary;
  font-family: Inter, sans-serif;
  line-height: normal;
}

a {
  color: inherit;
  text-decoration: none;
}

// Components
@import "./Button.scss";
@import "./Card.scss";
@import "./SimpleDropdown.scss";
@import "./ProtectedLayout.scss";
@import "./FlamingoAttribution.scss";

// Views
@import "./Login.scss";
@import "./Main.scss";
@import "./Settings.scss";
@import "../views/dashboards/dashboards.scss";
