$flamingo-attribution-height: 60px;

.flamingo-attribution {
    .wrapper {
        width: fit-content;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        padding: 10px 20px;
        background: $card-dark-background;
        border-radius: 8px;
    }

    height: $flamingo-attribution-height;
    font-size: 14px;

    span {
        color: $text-color-gray-on-dark;
        margin-right: 12px;
    }

    img {
        width: 32px;
        height: 32px;
        opacity: 0.4;
    }
}